.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.App-logo {
  transition: .5s ease;
  height: 55px;
  margin: .25em;
  padding: 0;
  pointer-events: click;
  border-radius: 50%;
}

.app-logo-container {
  width: 5vw;
}

.App-header {
  background-color: #282c34;
  display: flex;
  align-items: center;
  font-size: calc(10px + 2vmin);
  width: 100vw;
  height: 10vh;
  color: white;
}

.logo-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95vw;
  font-family: Arial, Helvetica, sans-serif;
}

.title-item {
  padding: 0.5em;
  margin: 0.5em;
}

.hamburger-menu-icon {
  justify-self: end;
  margin: 0.5em;
}

.hamburger-menu {
  height: min-content;
  width: 5em;
  position: absolute;
  right: 0;
  display: flex;
  flex-flow: vertical nowrap;
  align-items: flex-start;
  background: green;
  border: 1px solid white;
}

.fa-border {
  border: solid 1px #67696d;
  padding: 0.1em 0.2em;
}

.App-link {
  color: #61dafb;
}

.content {
  width: 100vw;
  min-height: 50vh;
  height: 90vh;
  background-color: #0f0f0f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.construction-notice {
  background-color: grey;
  max-width: 15vw;
  min-width: 240px;
  max-height: 15vh;
  min-height: 155px;
  padding: 1em;
  margin: 1em;
  border: solid #67696d;
  border-radius: 1em;

}

@keyframes App-logo-pulse {
  0% {
    width: initial;
  }
  5% {
    transform: scale(.9);
  }
  10% {
    transform: scale(1);
  }
  15% {  
    transform: scale(.9);
  }
  20% {
    transform: scale(1);
  }
}
